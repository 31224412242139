import Layout from "../components/layout"
import React from "react"
import BackgroundSlider from 'gatsby-image-background-slider'
import { useStaticQuery, graphql } from "gatsby"


import MainMenu from "../components/mainMenu"
import SEO from "../components/seo"


const IndexPage = () => {
  
  const data = useStaticQuery(graphql`
    query {
      allDirectusMainPageBackgroundSlide(sort: {fields: sort, order: ASC}) {
        nodes {
          slide {
            localFile {
              relativePath
              childImageSharp {
                fluid(maxWidth: 1200, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }`
  )

  const slideData = { 'backgrounds': { 'nodes':  data.allDirectusMainPageBackgroundSlide.nodes.map((node) => { return node.slide.localFile } ) }}
  
  return (
    <Layout>
      <MainMenu />
      <SEO title="Entry" />
      <BackgroundSlider query={slideData} />
    </Layout>
  )
}

export default IndexPage
