import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"

const MainMenu = () => {
  const data = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        menuLinks {
          link
          name
        }
      }
    }
  }
 `)
  const menuLinks = data.site.siteMetadata.menuLinks
  return (
    <div className="fixed bottom-0 right-0">
      <div className="flex flex-col">
        {menuLinks.map(link => (
          <div key={link.name}><a className="menu-item text-3xl" href={link.link}>{link.name}</a></div>
        ))}
        <div className="menu-item opacity-100">
        <Link className="hidden xs:flex pl-4 text-3xl" to="/">CHRISTOFFER JOERGENSEN</Link>
        <Link className="xs:hidden pl-4 text-3xl" to="/">C. JOERGENSEN</Link>
        </div>
      </div>
    </div>
)}

export default MainMenu
